import React from 'react'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import CustomForm from '../components/custom-form';

const ContactPage = props => {
  // const {data, errors} = props

  // if (errors) {
  //   return (
  //     <Layout>
  //       <GraphQLErrorList errors={errors} />
  //     </Layout>
  //   )
  // }

  return (
    <Layout>
      <SEO title='Contact' />
      <Container>
        <h1 hidden>Contact freelance Web Designer, Developer, Product Manager</h1>
        <CustomForm />
      </Container>
    </Layout>
  )
}

export default ContactPage
