import React from 'react'
import { navigate } from 'gatsby'

import styles from './custom-form.module.css'

const CustomForm = props => {
  const [state, setState] = React.useState({})

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/contact', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        // 'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <form className={styles.root}
      name="contact-recaptcha"
      method="post"
      action="/thanks/"
      data-netlify="true"
      // data-netlify-recaptcha="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      >
      <input type="hidden" name="bot-field" />
      <noscript>
        <p>This form won’t work with Javascript disabled</p>
      </noscript>
      <div className={styles.labelContainer}>
        <label >
          <p className={styles.labelText}>Name</p>
          <input className={styles.input} type="text" name="name" onChange={handleChange} />
        </label>
      </div>
      <div className={styles.labelContainer}>
        <label className={styles.labelContainer}>
        <p className={styles.labelText}>Email</p>
          <input className={styles.input} type="email" name="email" onChange={handleChange} />
        </label>
      </div>
      <div className={styles.labelContainer}>
        <label className={styles.labelContainer}>
        <p className={styles.labelText}>Message</p>
          <textarea className={styles.input} rows={10} name="message" onChange={handleChange} />
        </label>
      </div>
      <button className={styles.submitButton} type="submit">Submit</button>
    </form>
  )
}

export default CustomForm
